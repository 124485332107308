<template>
  <div class="container deal-menu-picker">
    <div class="scroll_area">
      <ul>
        <li v-for="(item, index) in items" :key="index">
          <div class="tag-box" :class="{'active': index === activeIdx}" @click='ev => pickItem(index)'>
            <img :src="getIconLinkItem(index)" alt="#">
            <h4>{{ getLabelItem(index) }}</h4>
          </div>
        </li>
      </ul>
    </div>
    <div class="tab-area" v-if="availableSubcategory">
      <ul class="tab-link">
        <li v-for="(subcate, index) in listSubcategory" :key="index"><button :class="['btn-link', idxActiveSubcategory===index ? 'active' : '']" :disabled="!availPickedCategory" @click="pickSubcategory(subcate.value)">{{ subcate.text }}</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppDealMenuPicker',
  props: {
    pickCodeCategory: {
      type: String,
      default: '',
    },
    pickCodeSubcategory: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeIdx: -1,
      idxActiveSubcategory: -1,
    };
  },
  computed: {
    ...mapGetters({
      menuItems: 'GET_MENU_ITEMS_APP_DEAL',
      availableSubcategory: 'GET_AVAILABLE_SUBCATEGORY',
      getSubcategory: 'GET_ACTIVE_SUBCATEGORIES',
      nonemptyCategories: 'GET_NONEMPTY_CATEGORIES',
    }),
    items() {
      return this.menuItems.filter((item) => this.nonemptyCategories.includes(item.code));
    },
    availPickedCategory() {
      return this.activeIdx > -1;
    },
    listSubcategory() {
      return this.getSubcategory(this.items?.[this.activeIdx]?.code ?? '');
    },
  },
  watch: {
    pickCodeCategory() {
      this.activeIdx = this.items.findIndex((cate) => cate.code === this.pickCodeCategory);
    },
    pickCodeSubcategory() {
      this.idxActiveSubcategory = this.listSubcategory.findIndex((cate) => cate.value === this.pickCodeSubcategory);
    },
    nonemptyCategories() {
      this.pickItem(0);
    },
  },
  mounted() {
    this.pickItem(0);
  },
  methods: {
    pickItem(pIdx) {
      this.idxActiveSubcategory = -1;
      this.activeIdx = pIdx;
      this.$emit('emitMessage', {
        kind: 'pickFilterCategory',
        data: {
          code: this.items?.[pIdx]?.code ?? '',
        },
      });
    },
    getIconLinkItem(pIdx) {
      if (pIdx < 0) return '';
      const item = this.items[pIdx];
      return pIdx === this.activeIdx ? item.icon2 : item.icon;
    },
    getLabelItem(pIdx) {
      if (pIdx < 0) return '';
      return this.items[pIdx].title;
    },
    pickSubcategory(pSubcategory) {
      this.idxActiveSubcategory = pSubcategory === 'The hottest' ? 0 : 1;
      this.$emit('emitMessage', {
        kind: 'pickSubcategory',
        data: {
          codeCategory: this.items?.[this.activeIdx]?.code ?? '',
          codeSubcategory: pSubcategory,
        },
      });
    },
  },
};
</script>

<style scoped>
  .deal-menu-picker{
    box-sizing: border-box;
    padding: 0px 15px;
    width: 100%;
    position: relative;
  }

  .bonauf-desktop .scroll_area{
    padding-top: 20px;
  }
  .scroll_area {
    /* overflow-x: scroll; */
    overflow-y:scroll;
    box-sizing: border-box;
    padding-bottom: 15px;
    padding-top: 60px;
  }
  .scroll_area::-webkit-scrollbar {
      width: 0.5em;
      height: 0px;
  }
  .scroll_area::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 1em;
  }
  .scroll_area::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 1em;
  }
  .scroll_area::-moz-scrollbar {
      width: 0.5em;
      height: 0.5em
  }
  .scroll_area::-moz-scrollbar-thumb {
      background-color: grey;
      border-radius: 1em;
  }
  .scroll_area::-moz-scrollbar-track {
      background-color: white;
      border-radius: 1em;
  }

    /* Set the momentum-based scrolling */
  .scroll_area {
      -webkit-overflow-scrolling: touch;
      -moz-overflow-scrolling: touch;
      -ms-overflow-scrolling: touch;
      overflow-scrolling: touch;
  }
  .scroll_area ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: table;
      width: 100%;
  }
  .scroll_area ul li {
      display: table-cell;
      vertical-align: top;
      list-style: none;
  }
  .tag-box {
      width: 84px;
      text-align: center;
      margin: auto;
  }
  .tag-box img {
      margin: 0 0 5px;
      object-fit: contain;
      height: 40px;
  }
  .bonauf-desktop .tag-box img {
    height: 65px;
    cursor: pointer;
  }
  .tag-box h4 {
      width: 100%;
      box-sizing: border-box;
      font-family: 'ploniregularaaa';
      font-size: 20px;
      margin: 0;
      padding: 0;
      line-height: 18px;
      position: relative;
  }
  .tag-box.active h4{
      padding-bottom: 10px;
      font-weight: 300;
      font-family: 'FbCoherentiSansBold';
      /* margin-top: 5px; */
  }
  .tag-box.active h4::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: rgb(251,109,47);
      background: -moz-linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
      background: linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
  }

  .tab-area .tab-link button.btn-link{
    text-decoration: unset;
  }
  .tab-area ul.tab-link {
    width: 100%;
    padding: 0;
    margin: 0 0 20px;
    list-style-type: none;
    text-align: center;
  }
  .tab-area ul.tab-link li {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
  .tab-area ul.tab-link li:last-child{
    margin: 0;
  }
  .tab-area ul.tab-link li .btn-link {
    padding: 0 10px;
    margin: 0;
    outline: none;
    border: 0;
    background-color: transparent;
    color: #000000;
    /* font-family: 'Rubik-Bold'; */
    font-size: 20px;
    position: relative;

    font-weight: normal;
    border: 1px solid #ffab91;
    border-radius: 20px;
  }
  /* .tab-area ul.tab-link li .btn-link::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: rgb(251,109,47);
    background: -moz-linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
    background: linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
    opacity: 0;
  } */
  /* .tab-area ul.tab-link li .btn-link.active::after{
    opacity: 1;
  } */
  .tab-area ul.tab-link li .btn-link.active {
    font-weight: bold;
    background-color: #ffab91;
  }
  .bonauf-desktop .tab-area ul.tab-link li .btn-link {
    background: linear-gradient(to right, #fdcfba, #fdfbac);
  }
  .bonauf-desktop .tab-area ul.tab-link li .btn-link.active {
    font-weight: bold;
    background: linear-gradient(to right, #fb732d, #f9f304);
    border: unset;
    padding: 0 14px;
    font-size: 24px;
  }
</style>
